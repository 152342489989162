/* eslint-disable no-console */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import classes from "./Error.module.scss";
import MetaTag from "../../components/MetaTag";
import { withCentre } from "../../hoc/CentreContext";
import { allowWifiAccess } from "../../utils/wlc";
import withVersion from "../../hoc/VersionContext/withVersion";

const Error = props => {
  useEffect(() => {
    try {
      const {
        location: { search },
        centreContext: {
          centre: { country }
        },
        routes,
        version
      } = props;

      if (version === "v1") {
        const queryParams = queryString.parse(search);
        allowWifiAccess(queryParams, country, routes);
      }
    } catch (e) {
      window.newrelic.noticeError(e);
    }
  });

  return (
    <div className={classes.content}>
      <MetaTag title="Error | Captive Portal" />
      <div className={classes.content}>
        An error occurred, but our technical team has been notified and will
        resolve it soon. We apologize for the inconvenience.
      </div>
      {/* This code removed as it is not being used for anything at the moment
      {props &&
        props.location &&
        props.location.state &&
        props.location.state.error && (
          <div hidden>
            The error:
            {props.location.state.error.toString()}
          </div>
        )}
      {props &&
        props.location &&
        props.location.state &&
        props.location.state.info && (
          <div hidden>
            Where it occurred:
            {props.location.state.info.componentStack}
          </div>
        )} */}
    </div>
  );
};

Error.propTypes = {
  centreContext: PropTypes.shape({
    centre: PropTypes.shape({
      country: PropTypes.string
    })
  }).isRequired,
  routes: PropTypes.shape({
    ERROR: PropTypes.string
  }).isRequired,
  version: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withVersion(withCentre(Error));
