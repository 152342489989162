/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import s from "./Input.module.scss";

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  errorText: PropTypes.node
};

const defaultProps = {
  errorText: "",
  hasError: false,
  label: "",
  name: "",
  type: "text",
  id: ""
};

const Input = ({ type, name, label, hasError, errorText, id, ...props }) => (
  <Fragment>
    {label && (
      <label className={s.label} htmlFor={id}>
        <span className={s.labelTitle}>{label}</span>
      </label>
    )}
    <input
      name={name}
      id={id}
      type={type}
      className={[s.formInput, hasError ? "is-invalid" : ""].join(" ")}
      {...props}
    />
    {hasError && s.errorMessage && (
      <span className={s.errorMessage}>{errorText}</span>
    )}
  </Fragment>
);

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
export default Input;
