/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import s from "./Anchor.module.scss";

const propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  theme: PropTypes.string
};

const defaultProps = {
  type: "link",
  href: "",
  className: "",
  size: "",
  theme: ""
};

const Anchor = ({ type, theme, size, href, children, className, ...props }) => (
  <a href={href} className={[s.anchor, className].join(" ")} {...props}>
    <div className={[s[type], s[theme], s[size]].join(" ")}>
      {type === "link" && children}
      {type === "button" && <div className={s.buttonText}>{children}</div>}
    </div>
  </a>
);

Anchor.propTypes = propTypes;
Anchor.defaultProps = defaultProps;
export default Anchor;
