import React from "react";
import CentreContext from "./CentreContext";

function withCenter(Component) {
  return function wrapperComponent(props) {
    return (
      <CentreContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </CentreContext.Consumer>
    );
  };
}

export default withCenter;
