import { API_ROOT, API_KEY } from "../constants/env";

const SdkClient = require("node_sdk").Client;

export default new SdkClient({
  domain: `${API_ROOT}/v1`,
  apiKey: {
    headerOrQueryName: "api_key",
    value: API_KEY,
    isQuery: true
  }
});
