import { ONE_API_ROOT, WIFI_CLIENT_ID } from "../constants/env";

async function partialAccount(params) {
  const data = await fetch(
    `${ONE_API_ROOT}/v1/register/partial?client_id=${WIFI_CLIENT_ID}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    }
  );
  const result = await data.json();
  return result;
}

export default { partialAccount };
