import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import CentreContext from './CentreContext';
import WFAPI from '../../utils/wf_api';
import { nonPIIParams, parseRequest } from '../../utils/wlc';

class CentreProvider extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      centre: {},
    };
  }

  setCentre = async centreId => {
    if (!centreId) {
      this.setState({ isLoaded: true, centre: { centreId } });
      return;
    }
    await Promise.all([
      WFAPI.getCentresByCentreId({ centreId }),
      WFAPI.getCentresNewsletters({ centreId }),
    ])
      .then(res => ({ ...res[0].body.data, ...res[1].body.data[0] }))
      .then(
        ({
          center_logo: centreLogo,
          short_name: centreName,
          primary_newsletter_id: newsletterPrimaryId,
          centre_pc_id: centerPcID,
          country,
        }) => {
          window.dataLayer.push({ center: centreId });
          this.setState({
            isLoaded: true,
            centre: {
              centreId,
              centreName,
              centreLogo,
              country,
              centerPcID,
              localeCountryCode: country.replace('UK', 'GB'),
              newsletterPrimaryId,
            },
          });
        },
      )
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error("Couldn't retrieve centre information: ", centreId, e);
        window.newrelic.noticeError(e, { centreId });
        this.setState({
          isLoaded: true,
          centre: { newsletterPrimaryId: `${centreId}_general`, centreId },
        });
      });
  };

  componentDidMount = async () => {
    const { location } = this.props;
    const { centreId } = parseRequest(location);
    this.setCentre(centreId);
    await this.onRouteChanged();
  };

  componentDidUpdate = async prevProps => {
    if (this.props.location !== prevProps.location) {
      await this.onRouteChanged();
    }
  };

  onRouteChanged = async () => {
    const {
      location: { search, pathname },
    } = this.props;
    const queryParams = queryString.parse(search);
    const newQueryParams = nonPIIParams(queryParams);

    const URL = `${pathname}?${queryString.stringify(newQueryParams)}`;
    const obj = {
      name: 'Page Change',
      start: Date.now(),
      origin: URL,
    };
    window.newrelic.addToTrace(obj);
    window.newrelic.interaction().setAttribute('queryParams', URL);
  };

  render() {
    const { children } = this.props;

    return (
      <CentreContext.Provider
        value={{
          centreContext: { ...this.state, setCentre: this.setCentre },
        }}
      >
        {children}
      </CentreContext.Provider>
    );
  }
}

CentreProvider.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(CentreProvider);
