export const V1 = {
  WELCOME: "/",
  REGISTER: "/register",
  SUCCESS: "/success",
  ERROR: "/error",
  SUCCESS_URL: `${window.location.origin}/success`
};

export const V2 = {
  WELCOME: "/v2/location/:centreId",
  REGISTER: "/v2/location/:centreId/register",
  SUCCESS: "/v2/location/:centreId/success",
  ERROR: "/v2/location/:centreId/error",
  SUCCESS_URL: `${window.location.origin}/v2/location/:centreId/success`
};
