import React from "react";
import { VersionContext } from "./VersionContext";

function withVersion(Component) {
  return function wrapperComponent(props) {
    return (
      <VersionContext.Consumer>
        {contexts => <Component {...props} {...contexts} />}
      </VersionContext.Consumer>
    );
  };
}

export default withVersion;
