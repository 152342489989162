import { ONE_API_ROOT } from "../constants/env";

async function legaltext() {
  const data = await fetch(
    `${ONE_API_ROOT}/v1/centers/info?include_legaltext=true`
  );
  const result = await data.json();
  return result.data;
}

export default legaltext;
