import React, { Component } from "react";
import PropTypes from "prop-types";
import { filterHtmlForPopup } from "../../helpers/html";
import { withCentre } from "../../hoc/CentreContext";
import { getPrivacyPolicyUrl } from "../../utils/westfield_urls";

class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      html: null
    };
  }

  componentDidMount() {
    const {
      centreContext: {
        centre: { country }
      }
    } = this.props;
    const countryLower = country.toLowerCase();

    fetch(getPrivacyPolicyUrl(countryLower))
      .then(res => res.text())
      .then(
        html => {
          const parser = new DOMParser().parseFromString(html, "text/html");
          let privacySection;
          if (countryLower === "us") {
            privacySection = parser.querySelector(
              "#main .container .sparsys-main"
            );
          } else {
            privacySection = parser.querySelector("body > div.container");
          }
          filterHtmlForPopup(privacySection);
          this.setState({ loading: false, html: privacySection.innerHTML });
        },
        () => {
          this.setState({ loading: false });
        }
      );
  }

  render() {
    const { loading, html } = this.state;

    if (loading) return <div>Loading...</div>;
    if (!html) return <div>Failed to load Privacy Policy.</div>;

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

Privacy.propTypes = {
  centreContext: PropTypes.shape({
    centre: PropTypes.shape({
      country: PropTypes.string
    })
  }).isRequired
};

export default withCentre(Privacy);
