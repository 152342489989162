/* eslint-disable no-console */
import React, { Component } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import queryString from "query-string";
import classes from "./Welcome.module.scss";
import Privacy from "../../components/Privacy";
import Terms from "../../components/Terms";
import closeBtn from "../../assets/images/close.svg";
import { withCentre } from "../../hoc/CentreContext";
import DefaultLayout from "../../layouts/Default";
import LinkButton from "../../components/LinkButton";
import Checkbox from "../../components/Checkbox";
import FormGroup from "../../components/FormGroup";
import { checkValidity } from "../../utils/form";
import Button from "../../components/Button";
import MetaTag from "../../components/MetaTag";
import withVersion from "../../hoc/VersionContext/withVersion";
import { nonPIIParams } from "../../utils/wlc";

const formValidations = {
  agree: {
    checked: {
      message: "Please check the box to proceed."
    }
  }
};
class Welcome extends Component {
  constructor() {
    super();

    this.state = {
      isPrivacyModel: false,
      isTermsModel: false,
      data: {
        agree: false
      },
      errors: {
        agree: ""
      },
      isSubmitted: false
    };
  }

  handleCloseModal = () => {
    this.setState({ isPrivacyModel: false, isTermsModel: false });
  };

  handleChange = e => {
    const { data, errors } = this.state;
    const { name, checked } = e.target;
    const fieldValidations = formValidations[name];
    let error = { errorMessage: "" };

    if (typeof fieldValidations !== "undefined") {
      error = checkValidity(checked, fieldValidations);
    }

    this.setState({
      data: {
        ...data,
        [name]: checked
      },
      errors: {
        ...errors,
        [name]: error.errorMessage
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const {
      history,
      routes,
      location: { search }
    } = this.props;
    const queryParams = queryString.parse(search);
    const newQueryParams = nonPIIParams(queryParams);

    // adding additional info for logging
    window.newrelic.addPageAction("welcomePageLoading", newQueryParams);

    const { data } = this.state;
    const error = checkValidity(data.agree, formValidations.agree);

    if (error.errorMessage) {
      this.setState({
        isSubmitted: true,
        errors: {
          agree: error.errorMessage
        }
      });
      return;
    }

    history.push({
      pathname: routes.REGISTER,
      search
    });
  };

  openModal(modelName) {
    if (modelName === "terms") {
      this.setState({ isPrivacyModel: false, isTermsModel: true });
    } else {
      this.setState({ isPrivacyModel: true, isTermsModel: false });
    }
  }

  render() {
    const {
      centreContext: { isLoaded }
    } = this.props;

    if (!isLoaded) {
      return null;
    }

    const {
      isPrivacyModel,
      isTermsModel,
      data: { agree },
      errors,
      isSubmitted
    } = this.state;

    return (
      <DefaultLayout>
        <MetaTag title="Welcome | Captive Portal" />
        <div className={classes.content}>
          <div className={classes.title}>FREE WI-FI</div>
          <div className={classes.description}>
            <div className={classes.descriptionBox}>
              <div>Welcome to Westfield</div>
              <div>
                Log on to our network once, and this device will automatically
                connect at participating Westfield centers everywhere you go.
              </div>
            </div>
          </div>
          <div className={classes.section} hidden>
            <div className={classes.sectionText}>
              <div>[INFO GRAPHIC]</div>
              <div>LEARN ABOUT WESTFIELD REWARDS AFTER WIFI ?</div>
            </div>
          </div>
          <div className={classes.col}>
            <FormGroup>
              <Checkbox
                name="agree"
                id="agree"
                value={agree}
                errorText={errors.agree}
                hasError={isSubmitted}
                onChange={this.handleChange}
              >
                <span>
                  {
                    "By clicking CONNECT, you agree that you’ve read and accepted our "
                  }
                  <LinkButton
                    type="button"
                    id="terms_button"
                    onClick={() => this.openModal("terms")}
                  >
                    <u>Terms & Conditions</u>
                  </LinkButton>
                  {" & "}
                  <LinkButton
                    type="button"
                    id="privacy_button"
                    onClick={() => this.openModal("privacy")}
                  >
                    <u>Privacy Policy</u>
                  </LinkButton>
                  .
                </span>
              </Checkbox>
            </FormGroup>
            <FormGroup>
              <Button
                type="button"
                id="connect"
                size="lg"
                onClick={this.handleSubmit}
              >
                Accept and Connect
              </Button>
            </FormGroup>
          </div>
          <Modal
            id="privacy_modal"
            overlayClassName="Overlay"
            isOpen={isPrivacyModel}
            onRequestClose={this.handleCloseModal}
            ariaHideApp={false}
            contentLabel="Privacy Policy"
            className={classes.popupModel}
          >
            <div className={classes.popupContent}>
              <div className={classes.popupHeader}>
                <div
                  role="button"
                  tabIndex="0"
                  id="privacy_modal_close"
                  className={classes.closeBtn}
                  onKeyPress={this.handleCloseModal}
                  onClick={this.handleCloseModal}
                >
                  <img src={closeBtn} alt="Close" />
                </div>
                <div className={classes.popupTitle}>Privacy Policy</div>
              </div>
              <div className={classes.popupBody}>
                <Privacy />
              </div>
            </div>
          </Modal>

          <Modal
            id="terms_modal"
            overlayClassName="Overlay"
            isOpen={isTermsModel}
            onRequestClose={this.handleCloseModal}
            ariaHideApp={false}
            contentLabel="Terms And Conditions"
            className={classes.popupModel}
          >
            <div className={classes.popupContent}>
              <div className={classes.popupHeader}>
                <div
                  role="button"
                  tabIndex="0"
                  id="terms_modal_close"
                  className={classes.closeBtn}
                  onKeyPress={this.handleCloseModal}
                  onClick={this.handleCloseModal}
                >
                  <img src={closeBtn} alt="Close" />
                </div>
                <div className={classes.popupTitle}>Terms And Conditions</div>
              </div>
              <div className={classes.popupBody}>
                <Terms />
              </div>
            </div>
          </Modal>
        </div>
      </DefaultLayout>
    );
  }
}

Welcome.propTypes = {
  centreContext: PropTypes.shape({
    isLoaded: PropTypes.bool
  }).isRequired,
  routes: PropTypes.shape({
    REGISTER: PropTypes.string
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withVersion(withCentre(Welcome));
