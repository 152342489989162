/* eslint-disable react/prop-types */
import React, { Component } from "react";
import classes from "./Header.module.scss";
import Logo from "./img/logo.png";
import { withCentre } from "../../hoc/CentreContext";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      centreContext: {
        centre: { centreName, centreLogo }
      }
    } = this.props;
    return (
      <div className={classes.header}>
        <div className={classes.logo}>
          <img
            className={classes.image}
            src={centreLogo || Logo}
            alt="Westfield"
          />
        </div>
        <div className={classes.title}>
          <span>{centreName || "Westfield"}</span>
        </div>
      </div>
    );
  }
}

export default withCentre(Header);
