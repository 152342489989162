import React, { Component } from "react";
import PropTypes from "prop-types";
import { filterHtmlForPopup } from "../../helpers/html";
import { withCentre } from "../../hoc/CentreContext";
import { getTermsUrl } from "../../utils/westfield_urls";

class Terms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      html: null
    };
  }

  componentDidMount() {
    const {
      centreContext: {
        centre: { country }
      }
    } = this.props;
    const countryLower = country.toLowerCase();
    fetch(getTermsUrl(countryLower))
      .then(res => res.text())
      .then(
        html => {
          const parser = new DOMParser().parseFromString(html, "text/html");
          const termsSection = parser.querySelector(
            "#main .container .sparsys-main"
          );
          filterHtmlForPopup(termsSection);
          this.setState({ loading: false, html: termsSection.innerHTML });
        },
        () => {
          this.setState({ loading: false });
        }
      );
  }

  render() {
    const { loading, html } = this.state;

    if (loading) return <div>Loading...</div>;
    if (!html) return <div>Failed to load Terms and Conditions.</div>;

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

Terms.propTypes = {
  centreContext: PropTypes.shape({
    centre: PropTypes.shape({
      country: PropTypes.string
    })
  }).isRequired
};

export default withCentre(Terms);
