import React from "react";
import PropTypes from "prop-types";
import s from "./FormGroup.module.scss";

const propTypes = {
  children: PropTypes.node.isRequired
};

const FormGroup = ({ children, ...props }) => (
  <div className={s.formGroup} {...props}>
    {children}
  </div>
);

FormGroup.propTypes = propTypes;
export default FormGroup;
