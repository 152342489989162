import { WESTFIELD_HOST, WESTFIELD_UK_HOST } from "../constants/env";
import { InvalidParameter } from "./errors";

export function getWestfieldHost(country) {
  switch (country) {
    case "us":
      return WESTFIELD_HOST;
    case "uk":
      return WESTFIELD_UK_HOST;
    default:
      throw new InvalidParameter("country", country);
  }
}

export function getPrivacyPolicyUrl(country) {
  let path;
  switch (country) {
    case "us":
      path = "/privacy-policy";
      break;
    case "uk":
      path = "/privacy";
      break;
    default:
      throw new InvalidParameter("country", country);
  }

  return `${getWestfieldHost(country)}${path}`;
}

export function getTermsUrl(country) {
  return `${getWestfieldHost(country)}/terms-and-conditions`;
}

export function getSMSTermsUrl(country) {
  return `${getWestfieldHost(country)}/sms-terms`;
}

export function getCentreURL(country, centreId) {
  return `${getWestfieldHost(country)}/${centreId}`;
}
