/* eslint-disable no-console */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import classes from "./Success.module.scss";
import DefaultLayout from "../../layouts/Default";
import Anchor from "../../components/Anchor";
import FormGroup from "../../components/FormGroup";
import MetaTag from "../../components/MetaTag";
import { withCentre } from "../../hoc/CentreContext";

const Success = props => {
  const {
    location: { search }
  } = props;
  const queryParams = queryString.parse(search);
  const redirectUrl = queryParams.redirect_url;

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 5000);
    return () => clearTimeout(timer);
  });

  return (
    <DefaultLayout>
      <MetaTag title="Success | Captive Portal" />
      <div className={classes.content}>
        <div className={classes.title}>SUCCESS</div>
        <div className={classes.description}>
          <div className={classes.descriptionBox}>
            <div>
              You’ve successfully logged onto Wifi. Enjoy your stay!
              <br />
              <br />
              You’ll be redirected to our site shortly.
            </div>
          </div>
        </div>
        <div className={classes.section} hidden>
          <div className={classes.sectionText}>
            <div>[INFO GRAPHIC]</div>
          </div>
        </div>
        <div className={classes.buttonBox}>
          <FormGroup>
            <Anchor
              id="continueBtn"
              type="button"
              theme="primary"
              size="lg"
              href={redirectUrl}
            >
              Continue to westfield.com
            </Anchor>
          </FormGroup>
        </div>
      </div>
    </DefaultLayout>
  );
};

Success.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default withCentre(Success);
