import { ONE_API_ROOT } from "../constants/env";

async function externalIdAPI(params) {
  try {
    const getdeleteURL = `${ONE_API_ROOT}/v1/visitors/${params.visitorId}/external-ids/${params.external_type}`;
    const getRes = await fetch(getdeleteURL);
    if (getRes.ok) {
      await fetch(getdeleteURL, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
    } else if (getRes.status !== 404) {
      throw new Error(`Failed to check external ID: ${getRes.statusText}`);
    }
    const createExtId = await fetch(
      `${ONE_API_ROOT}/v1/visitors/${params.visitorId}/external-ids`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      }
    );

    if (createExtId.ok) {
      return createExtId;
    }
    throw new Error((await createExtId.json()).message);
  } catch (error) {
    throw error;
  }
}

export default { externalIdAPI };
