import React from "react";
import PropTypes from "prop-types";
import Header from "../../components/Header";

const propTypes = {
  children: PropTypes.node.isRequired
};

const Default = ({ children }) => (
  <div className="content">
    <Header />
    <div className="root">{children}</div>
  </div>
);

Default.propTypes = propTypes;

export default Default;
