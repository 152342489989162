import locationMapping from "../constants/location_id_mappings";

// eslint-disable-next-line import/prefer-default-export
export const getCenterIdFromLocation = location => {
  const len = locationMapping.length;
  let centreId;

  for (let i = 0; i < len; i += 1) {
    if (locationMapping[i].match.toLowerCase() === location.toLowerCase()) {
      centreId = locationMapping[i].center_id;
      break;
    }
  }

  return centreId;
};
