/* eslint-disable import/prefer-default-export */
import {
  isEmail,
  isEmpty,
  isLength,
  isAlphanumeric,
  blacklist
} from "validator";

export const checkValidity = (value, rules) => {
  let isValid = true;
  let errorMessage = "";

  if (!rules) return { isValid, errorMessage };

  if (rules.required) {
    if (isEmpty(value)) {
      isValid = false;
      errorMessage = rules.required.message;
    }
  }

  if (rules.checked) {
    if (value !== true) {
      isValid = false;
      errorMessage = rules.checked.message;
    }
  }

  if (isValid && rules.isAlphanumericWithApostophes) {
    if (!isAlphanumeric(blacklist(value, [" ", "'"]))) {
      isValid = false;
      errorMessage = rules.isAlphanumericWithApostophes.message;
    }
  }

  if (isValid && rules.isAlphanumeric) {
    if (!isAlphanumeric(blacklist(value, " "))) {
      isValid = false;
      errorMessage = rules.isAlphanumeric.message;
    }
  }

  if (isValid && rules.isNumericOrDash) {
    if (!value.match(/^[0-9-]*$/g)) {
      isValid = false;
      errorMessage = rules.isNumericOrDash.message;
    }
  }

  if (isValid && rules.email) {
    if (!isEmail(value, { allow_utf8_local_part: false })) {
      isValid = false;
      errorMessage = rules.email.message;
    }
  }

  if (isValid && rules.minLength) {
    if (!isLength(value, { min: rules.minLength.length })) {
      isValid = false;
      errorMessage = rules.minLength.message;
    }
  }

  if (isValid && rules.maxLength) {
    if (!isLength(value, { max: rules.maxLength.length })) {
      isValid = false;
      errorMessage = rules.maxLength.message;
    }
  }

  if (isValid && rules.PHONE_TOO_SHORT) {
    if (value === "TOO_SHORT") {
      isValid = false;
      errorMessage = rules.PHONE_TOO_SHORT.message;
    }
  }

  if (isValid && rules.PHONE_TOO_LONG) {
    if (value === "TOO_LONG") {
      isValid = false;
      errorMessage = rules.PHONE_TOO_LONG.message;
    }
  }

  if (isValid && rules.PHONE_INVALID_COUNTRY) {
    if (value === "INVALID_COUNTRY") {
      isValid = false;
      errorMessage = rules.PHONE_INVALID_COUNTRY.message;
    }
  }

  return { isValid, errorMessage };
};
