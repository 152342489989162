/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

import s from "./Checkbox.module.scss";

const propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  hasError: PropTypes.bool,
  errorText: PropTypes.node
};

const defaultProps = {
  errorText: "",
  hasError: false,
  children: null,
  name: "",
  id: ""
};

const Checkbox = ({ name, id, children, hasError, errorText, ...props }) => (
  <Fragment>
    <input
      name={name}
      type="checkbox"
      id={id}
      className={[s.formCheckInput, hasError ? s["is-invalid"] : ""].join(" ")}
      {...props}
    />
    {children && (
      <label className={s.formCheckLabel} htmlFor={id}>
        {children}
      </label>
    )}
    {hasError && s.errorMessage && (
      <span className={s.errorMessage}>{errorText}</span>
    )}
  </Fragment>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;
export default Checkbox;
