/* eslint-disable no-console */
import React, { Component } from 'react';
import Modal from 'react-modal';
import { Container, Row, Col, Form } from 'react-bootstrap';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import classes from './Register.module.scss';
import SMSTerms from '../../components/SMSTerms';
import { checkValidity } from '../../utils/form';
import { withCentre } from '../../hoc/CentreContext';
import legaltext from '../../utils/legal_text_api';
import 'react-phone-number-input/style.css';
import Partialaccount from '../../utils/visitor_api_partial_account';
import formValidations from './formValidations.json';
import DefaultLayout from '../../layouts/Default';
import Input from '../../components/Input';
import Button from '../../components/Button';
import FormGroup from '../../components/FormGroup';
import Checkbox from '../../components/Checkbox';
import MetaTag from '../../components/MetaTag';
import closeBtn from '../../assets/images/close.svg';
import withVersion from '../../hoc/VersionContext/withVersion';
import { allowWifiAccess, parseRequest, nonPIIParams } from '../../utils/wlc';
import externalIdAPI from '../../utils/external_id_API';

class Register extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        zipCode: '',
        postCode: '',
        mobileNumber: '',
        optin: false,
        smsMarketingOptIn: false,
        showSmsMarketingOptIn: false,
      },
      disabled: true,
      displaytext: '',
      legalTextId: '',
      countryCode: '',
      isSmsTermsModel: false,
      formSubmit: false,
      isSubmitted: false,
      isLoading: true,
      errors: {},
    };
  }

  static getDerivedStateFromProps(
    {
      centreContext: { centre },
      location,
    },
    state,
  ) {
    if (centre.country !== state.countryCode) {
      const { externalId } = parseRequest(location);
      return {
        countryCode: centre.localeCountryCode || '',
        externalId,
      };
    }
    return null;
  }

  async componentDidMount() {
    const text = await legaltext();
    text.legal_texts.forEach(data => {
      if (data.type === 'commercial_email') {
        this.setState({ displaytext: data.optinDisplayText });
        this.setState({ legalTextId: data.id });
        this.setState({ isLoading: false });
      }
    });
  }

  handleChange = e => {
    const { data, errors, formSubmit } = this.state;
    const { name, type, value, checked } = e.target;
    const fieldValidations = formValidations[name];
    let error = { errorMessage: '' };

    if (formSubmit && typeof fieldValidations !== 'undefined') {
      if (name === 'optin') {
        error = checkValidity(checked, fieldValidations);
      } else {
        error = checkValidity(value, fieldValidations);
      }
    }
    if (name === 'optin') {
      this.setState({ disabled: data.optin });
    }

    this.setState({
      data: {
        ...data,
        [name]: type === 'checkbox' ? checked : value,
      },
      errors: {
        ...errors,
        [name]: error.errorMessage,
      },
    });
  };

  changeCountry = code => {
    this.setState({ countryCode: code });
  };

  checkPhoneNumberValidation = number => {
    const { countryCode } = this.state;
    let error = { errorMessage: '' };
    const validation = validatePhoneNumberLength(number, countryCode);
    if (validation !== undefined) {
      error = {
        errorMessage: checkValidity(validation, formValidations.mobileNumber).errorMessage,
      };
    }
    return error;
  };

  handleSubmit = e => {
    e.preventDefault();

    const {
      centreContext: {
        centre: { centreId, country, centerPcID },
      },
      location,
      routes,
    } = this.props;

    const queryParams = queryString.parse(location.search);
    const { data, externalId } = this.state;
    const errors = {};
    const isUK = country === 'UK';

    // Validate form
    const formFields = Object.keys(formValidations);
    formFields.splice(formFields.indexOf(isUK ? 'zipCode' : 'postCode'), 1);
    if (
      (data.mobileNumber === undefined || data.mobileNumber === '') &&
      data.smsMarketingOptIn === false
    ) {
      formFields.splice(formFields.indexOf('mobileNumber'), 1);
    }

    formFields.forEach(fieldName => {
      let value;
      if (fieldName !== 'optin') {
        value = data[fieldName].trim();
      }
      const error =
        fieldName === 'optin'
          ? checkValidity(data[fieldName], formValidations[fieldName])
          : checkValidity(value, formValidations[fieldName]);
      if (error.errorMessage) errors[fieldName] = error.errorMessage;
    });

    if (Object.keys(errors).length !== 0) {
      this.setState({
        formSubmit: true,
        errors,
      });
      return;
    }

    const { email, firstName, lastName, optin } = data;
    const params = {
      email: email.trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),

      gender: 'u',
      countryCode: country,
      pc_code: centerPcID,
      optin_commercial: optin,
      optin_commercial_textid: this.state.legalTextId,
    };

    this.setState({ isSubmitted: true }, () => {
      Partialaccount.partialAccount(params)
        .then(response => {
          const assignExternalIdParams = {
            external_id: externalId ? externalId.trim() : externalId,
            visitorId: response.visitor_id,
            external_type: 'wifi_device_id',
          };

          // Assign external id to register user
          return externalIdAPI
            .externalIdAPI(assignExternalIdParams)
            .then(async () => {
              const newQueryParams = nonPIIParams(queryParams);
              const { redirectUrl } = await allowWifiAccess(
                queryParams,
                country,
                routes,
                centreId.trim(),
              );
              // adding additional info for logging
              window.newrelic.addPageAction('successRedirect', {
                ...newQueryParams,
                redirectPage: redirectUrl,
              });

              if (redirectUrl) {
                window.location.replace(redirectUrl);
              }
            })
            .catch(error => {
              this.setState(() => {
                // adding additional error markers for NR Logs
                const errorParams = JSON.parse(JSON.stringify(assignExternalIdParams));

                // adding additional notice errors for logging
                window.newrelic.noticeError(error, {
                  marker: 'postAccountExternalID',
                  makerParams: errorParams,
                });

                if (error instanceof Error) {
                  throw error;
                }
                throw new Error(
                  `Couldn't submit external_id information: ${JSON.stringify(error)}`,
                );
              });
            });
        })
        .catch(error => {
          this.setState(() => {
            // adding additional error markers for NR Logs
            const errorParams = JSON.parse(JSON.stringify(params));
            errorParams.email = errorParams.email.replace(/(.{3}).*(.{6})/i, '$1xxxx@xx$2');
            errorParams.first_name = errorParams.first_name.replace(/(.{1}).*(.{1})/i, '$1xx$2');
            errorParams.last_name = errorParams.last_name.replace(/(.{1}).*(.{1})/i, '$1xx$2');
            if (errorParams.phone_number) {
              errorParams.phone_number = errorParams.phone_number.replace(
                /(.{5}).*/i,
                '$1xxxxxxx',
                // Example of a phone number after Obfuscation: +1123xxxxxxx
              );
            }

            // adding additional notice errors for logging
            window.newrelic.noticeError(error, {
              marker: 'postAccount',
              makerParams: errorParams,
            });

            if (error instanceof Error) {
              throw error;
            }

            throw new Error(`Couldn't submit account information: ${JSON.stringify(error)}`);
          });
        });
    });
  };

  handleCloseModal = () => {
    this.setState({ isSmsTermsModel: false });
  };

  openModal() {
    this.setState({ isSmsTermsModel: true });
  }

  render() {
    // eslint-disable-next-line no-unused-expressions
    const {
      centreContext: {
        isLoaded,
        centre: { country },
      },
    } = this.props;

    if (!isLoaded) {
      return null;
    }

    const {
      data: { firstName, lastName, email, zipCode, postCode, optin },
      disabled,
      isSubmitted,
      formSubmit,
      isSmsTermsModel,
      displaytext,
      errors,
      isLoading,
    } = this.state;
    const isUK = country === 'UK';

    return (
      <DefaultLayout>
        <MetaTag title="Register | Captive Portal" />
        <div className={classes.loader} style={{ display: isLoading ? 'block' : 'none' }} />
        <Container className={classes.container} style={{ opacity: isLoading ? '0' : '1' }}>
        <Form onSubmit={this.handleSubmit} className={classes.registerForm}>
            <Row>
              <Col lg="12" className={classes.col}>
                <FormGroup>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={this.handleChange}
                    disabled={isSubmitted}
                    hasError={formSubmit}
                    errorText={errors.firstName}
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className={classes.col}>
                <FormGroup>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    label="Last Name"
                    value={lastName}
                    onChange={this.handleChange}
                    disabled={isSubmitted}
                    hasError={formSubmit}
                    errorText={errors.lastName}
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className={classes.col}>
                <FormGroup>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    label="E-mail-Address"
                    value={email}
                    onChange={this.handleChange}
                    disabled={isSubmitted}
                    hasError={formSubmit}
                    errorText={errors.email}
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className={classes.col}>
                <FormGroup>
                  <Input
                    type="text"
                    name={isUK ? 'postCode' : 'zipCode'}
                    id={isUK ? 'postCode' : 'zipCode'}
                    label={isUK ? 'Post Code' : 'Zip Code'}
                    value={isUK ? postCode : zipCode}
                    onChange={this.handleChange}
                    disabled={isSubmitted}
                    hasError={formSubmit}
                    errorText={isUK ? errors.postCode : errors.zipCode}
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className={classes.col}>
                <FormGroup>
                  <Checkbox
                    name="optin"
                    id="optin"
                    value={optin}
                    hasError={formSubmit}
                    disabled={isSubmitted}
                    onChange={this.handleChange}
                    errorText={errors.optin}
                  >
                    <div dangerouslySetInnerHTML={{ __html: displaytext }} />
                  </Checkbox>
                </FormGroup>
              </Col>
              <Col lg="12" className={classes.col}>
                <FormGroup>
                  <Button
                    type="submit"
                    id="continue_button"
                    theme="primary"
                    size="lg"
                    disabled={disabled}
                  >
                    Continue
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <Modal
            id="sms_terms_modal"
            overlayClassName="Overlay"
            isOpen={isSmsTermsModel}
            onRequestClose={this.handleCloseModal}
            ariaHideApp={false}
            contentLabel="SMS Terms And Conditions"
            className={classes.popupModel}
          >
            <div className={classes.popupContent}>
              <div className={classes.popupHeader}>
                <div
                  role="button"
                  tabIndex="0"
                  id="sms_terms_modal_close"
                  className={classes.closeBtn}
                  onKeyPress={this.handleCloseModal}
                  onClick={this.handleCloseModal}
                >
                  <img src={closeBtn} alt="Close" />
                </div>
                <div className={classes.popupTitle}>SMS Terms And Conditions</div>
              </div>
              <div className={classes.popupBody}>
                <SMSTerms />
              </div>
            </div>
          </Modal>
        </Container>
      </DefaultLayout>
    );
  }
}

Register.propTypes = {
  centreContext: PropTypes.shape({
    isLoaded: PropTypes.bool,
    centre: PropTypes.shape({
      localeCountryCode: PropTypes.string,
      centreId: PropTypes.string,
      centreName: PropTypes.string,
      country: PropTypes.string,
      centerPcID: PropTypes.string,
      newsletterPrimaryId: PropTypes.string,
    }),
  }).isRequired,
  routes: PropTypes.shape({
    SUCCESS: PropTypes.string,
    SUCCESS_URL: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withVersion(withCentre(Register));
