/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import s from "./Button.module.scss";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
  theme: PropTypes.string
};

const defaultProps = {
  className: "",
  type: "button",
  size: "",
  theme: ""
};

const Button = ({ type, className, children, size, theme, ...props }) => (
  <button
    type={type}
    className={[s.button, s[theme], s[size], className].join(" ")}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
export default Button;
