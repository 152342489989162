// eslint-disable-next-line import/prefer-default-export
export const filterHtmlForPopup = ele => {
  if (ele.classList !== undefined && ele.classList.length !== 0) {
    ele.removeAttribute("class");
  }

  if (ele.tagName === "A") {
    ele.removeAttribute("href");
    ele.removeAttribute("target");
  }

  ele.childNodes.forEach(node => filterHtmlForPopup(node));
};
