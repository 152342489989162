import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary";
import Welcome from "./pages/Welcome";
import Register from "./pages/Register";
import Success from "./pages/Success";
import Error from "./pages/Error";
import { CentreProvider } from "./hoc/CentreContext";
import { V1, V2 } from "./constants/routes";
import { VersionProvider } from "./hoc/VersionContext/VersionContext";

const App = () => (
  <BrowserRouter>
    <VersionProvider>
      <ErrorBoundary>
        <CentreProvider>
          <Switch>
            <Route exact path={V1.WELCOME} component={Welcome} />
            <Route exact path={V1.REGISTER} component={Register} />
            <Route exact path={V1.SUCCESS} component={Success} />
            <Route exact path={V1.ERROR} component={Error} />
            <Route exact path={V2.WELCOME} component={Welcome} />
            <Route exact path={V2.REGISTER} component={Register} />
            <Route exact path={V2.SUCCESS} component={Success} />
            <Route exact path={V2.ERROR} component={Error} />
            <Route path="*" component={Error} />
          </Switch>
        </CentreProvider>
      </ErrorBoundary>
    </VersionProvider>
  </BrowserRouter>
);

export default App;
