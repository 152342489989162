export const API_ROOT_SECURE = process.env.REACT_APP_API_ROOT_SECURE;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_KEY_SECURE = process.env.REACT_APP_API_KEY_SECURE;
export const SKIP_CAPTCHA_HEADER_VALUE =
  process.env.REACT_APP_SKIP_CAPTCHA_HEADER_VALUE;
export const IS_OPENED_BY_CISCO = window.parent !== window;
export const WESTFIELD_HOST = process.env.REACT_APP_WESTFIELD_HOST;
export const WESTFIELD_UK_HOST = process.env.REACT_APP_WESTFIELD_UK_HOST;
export const ONE_API_ROOT = process.env.REACT_APP_ONE_API_ROOT;
export const WIFI_CLIENT_ID = process.env.REACT_APP_WIFI_CLIENT_ID;
export const OID_NAMESPACE = "6ba7b812-9dad-11d1-80b4-00c04fd430c8";
export const REDIRECT_DETAILS = {
  UK: {
    username: process.env.REACT_APP_UK_REDIRECT_USERNAME,
    password: process.env.REACT_APP_UK_REDIRECT_PASSWORD,
    buttonClicked: process.env.REACT_APP_UK_REDIRECT_BUTTON_CLICKED
  },
  US: {
    username: process.env.REACT_APP_US_REDIRECT_USERNAME,
    password: process.env.REACT_APP_US_REDIRECT_PASSWORD,
    buttonClicked: process.env.REACT_APP_US_REDIRECT_BUTTON_CLICKED
  }
};
