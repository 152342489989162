import { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import withVersion from "../../hoc/VersionContext/withVersion";

class ErrorBoundary extends Component {
  async componentDidCatch(error) {
    const {
      location: { search },
      routes,
      history
    } = this.props;
    window.newrelic.noticeError(error);

    history.push({
      pathname: routes.ERROR,
      search
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  routes: PropTypes.shape({
    ERROR: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withVersion(withRouter(ErrorBoundary));
