/* eslint-disable no-console */
import queryString from "query-string";
import { v5 as uuidv5 } from "uuid";
import {
  REDIRECT_DETAILS,
  OID_NAMESPACE,
  IS_OPENED_BY_CISCO
} from "../constants/env";
import { getCenterIdFromLocation } from "../helpers/string";
import { getCentreURL } from "./westfield_urls";

export const nonPIIParams = queryParams => {
  const {
    client_mac: cMac,
    node_mac: nMac,
    client_ip: cIp,
    client_id: cId,
    ...newQueryParams
  } = queryParams;
  return newQueryParams;
};

const logError = (error, params) => {
  const e = new Error(error);
  window.newrelic.noticeError(e, params);
  console.error(e);
};

const generateClientMacUuid = clientMac => uuidv5(clientMac, OID_NAMESPACE);

const parseCiscoWIFI = (queryParams, pathName) => {
  let centreId;
  const {
    locationname: locationName,
    p_status: pStatus,
    client_id: clientId
  } = queryParams;

  const errorPramas = { pathName, queryParams };
  if (!pStatus) {
    logError("p_status is not provided", errorPramas);
  }

  if (!clientId) {
    logError("client_id is not provided", errorPramas);
  }

  if (!locationName) {
    logError("locationname is not provided", errorPramas);
  } else {
    centreId = getCenterIdFromLocation(locationName) || locationName;
  }

  return {
    centreId,
    redirectUrl: "",
    externalId: clientId
  };
};

const ciscoWifi = (search, countryCode, routes, centreId) => {
  let continueUrl;

  if (countryCode && search.locationname) {
    const centreUrl = getCentreURL(countryCode.toLowerCase(), centreId);

    continueUrl = `${routes.SUCCESS}?redirect_url=${encodeURIComponent(
      centreUrl
    )}&${queryString.stringify(search)}`;
  }

  window.parent.postMessage(
    {
      start_provisioning: true,
      landing_page: continueUrl
    },
    "*"
  );

  return { redirectUrl: IS_OPENED_BY_CISCO ? null : continueUrl };
};

const parseWLC = (queryParams, pathName) => {
  let centreId;
  const [, , , centerParam] = pathName.split("/");
  const {
    switch_url: switchUrl,
    client_mac: clientMac,
    redirect_url: redirectUrl,
    redirect
  } = queryParams;

  const errorPramas = { pathName, queryParams: nonPIIParams(queryParams) };

  if (!centerParam) {
    logError("locationname is not provided", errorPramas);
  } else {
    centreId = getCenterIdFromLocation(centerParam) || centerParam;
  }

  if (!switchUrl) {
    logError("switch_url is not provided", errorPramas);
  }

  if (!clientMac) {
    logError("client_mac is not provided", errorPramas);
  }

  if (!redirect && !redirectUrl) {
    logError("redirect or redirect_url is not provided", errorPramas);
  }

  return {
    centreId,
    redirectUrl: redirect || redirectUrl,
    externalId: clientMac ? generateClientMacUuid(clientMac) : null
  };
};

const parseMerakiClickThroughBase = (queryParams, pathName) => {
  let centreId;
  const [, , , centerParam] = pathName.split("/");
  const {
    base_grant_url: baseGrantUrl,
    client_ip: clientIp,
    gateway_id: gatewayId,
    node_id: nodeId,
    node_mac: nodeMac,
    user_continue_url: userContinueUrl
  } = queryParams;

  const errorPramas = { pathName, queryParams: nonPIIParams(queryParams) };

  if (!centerParam) {
    logError("locationname is not provided", errorPramas);
  } else {
    centreId = getCenterIdFromLocation(centerParam) || centerParam;
  }

  if (!baseGrantUrl) {
    logError("base_grant_url is not provided", errorPramas);
  }

  if (!clientIp) {
    logError("client_ip is not provided", errorPramas);
  }

  if (!gatewayId) {
    logError("gateway_id is not provided", errorPramas);
  }

  if (!nodeId) {
    logError("node_id is not provided", errorPramas);
  }

  if (!nodeMac) {
    logError("node_mac is not provided", errorPramas);
  }

  if (!userContinueUrl) {
    logError("user_continue_url is not provided", errorPramas);
  }

  return {
    centreId,
    redirectUrl: userContinueUrl,
    externalId: nodeMac ? generateClientMacUuid(nodeMac) : null
  };
};

const merakiClickThroughBasedSplashPage = search => {
  const url = `${search.base_grant_url}?continue_url=${search.user_continue_url}`;
  return {
    redirectUrl: url
  };
};

const allowWLC = (queryParams, countryCode) => {
  const REDIRECT = REDIRECT_DETAILS[countryCode];

  const form = document.createElement("form");
  form.method = "post";
  form.action = queryParams.switch_url;

  const redirectInput = document.createElement("input");
  redirectInput.name = "redirect_url";
  redirectInput.type = "hidden";
  redirectInput.value = queryParams.redirect;
  form.append(redirectInput);

  const clickInput = document.createElement("input");
  clickInput.name = "buttonClicked";
  clickInput.type = "hidden";
  clickInput.value = REDIRECT?.buttonClicked || 4;
  form.append(clickInput);

  const errInput = document.createElement("input");
  errInput.name = "err_flag";
  errInput.type = "hidden";
  errInput.value = 0;
  form.append(errInput);

  if (REDIRECT?.username) {
    const uNameInput = document.createElement("input");
    uNameInput.name = "username";
    uNameInput.type = "hidden";
    uNameInput.value = REDIRECT.username;
    form.append(uNameInput);
  }

  if (REDIRECT?.password) {
    const pwdInput = document.createElement("input");
    pwdInput.name = "password";
    pwdInput.type = "hidden";
    pwdInput.value = REDIRECT.password;
    form.append(pwdInput);
  }

  document.body.append(form);
  form.submit();

  return { redirectUrl: null };
};

export const allowWifiAccess = (search, countryCode, routes, centreId) => {
  let out = {};
  if (typeof search.locationname !== "undefined") {
    out = ciscoWifi(search, countryCode, routes, centreId);
  } else if (typeof search.base_grant_url !== "undefined") {
    out = merakiClickThroughBasedSplashPage(search);
  } else if (typeof search.switch_url !== "undefined") {
    out = allowWLC(search, countryCode);
  }
  return out;
};

export const parseRequest = location => {
  const queryParams = queryString.parse(location.search);
  let out = {};
  if (typeof queryParams.locationname !== "undefined") {
    out = parseCiscoWIFI(queryParams, location.pathname);
  } else if (typeof queryParams.switch_url !== "undefined") {
    out = parseWLC(queryParams, location.pathname);
  } else if (typeof queryParams.base_grant_url !== "undefined") {
    out = parseMerakiClickThroughBase(queryParams, location.pathname);
  }
  return out;
};

export default { allowWifiAccess, parseRequest, nonPIIParams };
