class AppError extends Error {}

export class ParameterError extends AppError {
  constructor(param, value, message) {
    super(message);
    this.param = param;
    this.value = value;
    this.message = message;
  }
}

export class InvalidParameter extends ParameterError {
  constructor(param, value) {
    super(param, value, `Invalid parameter: ${param} (${value})`);
  }
}
