const locationMapping = [
  { match: "Century City", center_id: "centurycity" },
  { match: "GSP", center_id: "gardenstateplaza" },
  { match: "Annapolis", center_id: "annapolis" },
  { match: "Brandon", center_id: "brandon" },
  { match: "Broward", center_id: "broward" },
  { match: "Citrus Park", center_id: "citruspark" },
  { match: "Countryside", center_id: "countryside" },
  { match: "Culver City", center_id: "culvercity" },
  { match: "Fashion Square", center_id: "fashionsquare" },
  { match: "London", center_id: "london" },
  // temp hotfix for invalid london url being passed via test configuration
  // we should remove this line once the root issue has been addressed
  {
    match: "london?switch_url=http://192.0.2.1/login.html",
    center_id: "london"
  },
  { match: "Mission Valley", center_id: "missionvalley" },
  { match: "Montgomery", center_id: "montgomery" },
  { match: "North County", center_id: "northcounty" },
  { match: "Oakridge", center_id: "oakridge" },
  { match: "Old Orchard", center_id: "oldorchard" },
  { match: "Palm Desert", center_id: "palmdesert" },
  { match: "Plaza Bonita", center_id: "plazabonita" },
  { match: "Roseville", center_id: "galleriaatroseville" },
  { match: "San Francisco Center", center_id: "sanfrancisco" },
  { match: "Santa Anita", center_id: "santaanita" },
  { match: "South Shore", center_id: "southshore" },
  { match: "South Center", center_id: "southcenter" },
  { match: "Topanga (meraki)", center_id: "topanga" },
  { match: "Trumbull", center_id: "trumbull" },
  { match: "UTC", center_id: "utc" },
  { match: "Valencia", center_id: "valencia" },
  { match: "Valley Fair", center_id: "valleyfair" },
  { match: "Wheaton", center_id: "wheaton" },
  { match: "WTC", center_id: "westfieldworldtradecenter" },
  { match: "Topanga (CMX)", center_id: "topanga" },
  { match: "UTC (CMX)", center_id: "topanga" }
];

export default locationMapping;
