import React, { useEffect, useState, createContext } from "react";
import { withRouter } from "react-router-dom";
import { V1, V2 } from "../../constants/routes";

// Context has been created
const VersionContext = createContext();

export function generateURL(path, routes) {
  const newRoutes = {};
  // Replace {centreId} URL params with respective centre.
  // example: interpret /v2/location/:centreId to /v2/location/london
  Object.keys(V2).forEach(key => {
    newRoutes[key] = routes[key].replace(/:centreId/g, path[3]);
  });

  return newRoutes;
}
// Provider
const VersionProvider = withRouter(({ children, location }) => {
  const [version, setVersion] = useState("");
  const [routes, setRoutes] = useState({});

  useEffect(() => {
    const { pathname } = location;
    const path = pathname.split("/");
    let v = "v1";
    let r = V1;

    if (path[1] === "v2") {
      v = "v2";
      r = generateURL(path, V2);
    }

    setVersion(v);
    setRoutes(r);
  }, [location]);

  return (
    <VersionContext.Provider value={{ version, routes }}>
      {children}
    </VersionContext.Provider>
  );
});

export { VersionContext, VersionProvider };
