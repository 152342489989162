/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import s from "./LinkButton.module.scss";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string
};

const defaultProps = {
  className: "",
  type: "button"
};

const LinkButton = ({ type, className, children, ...props }) => (
  <button
    type={type}
    className={[s.linkButton, className].join(" ")}
    {...props}
  >
    {children}
  </button>
);

LinkButton.propTypes = propTypes;
LinkButton.defaultProps = defaultProps;
export default LinkButton;
