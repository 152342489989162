/* eslint-disable react/button-has-type */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const propTypes = {
  title: PropTypes.string
};

const defaultProps = {
  title: "Captive Portal"
};

const MetaTag = ({ title }) => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
);

MetaTag.propTypes = propTypes;
MetaTag.defaultProps = defaultProps;
export default MetaTag;
